import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header className="header">
            <div className="container col-md-2">
                <div className="row">
                    {/* <div className="col-6 col-md-4 col-xl-6 header__logo-wrap"> */}
                        <Link className="header__logo" to="">
                            <img className="header__logo-img" src="images/vreli-logo.png"
                                alt="VReli Logo" />
                        </Link>
                    {/* </div> */}
                </div>
            </div>
        </header>)
}

export default Header