import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppProvider';
import Card from './FindWorkspaceData';
import { useLocation,useNavigate } from "react-router-dom"
// import {Sdata} from './ConfirmOTP';

const FindWorkspace = () => {
    const { workspaces } = React.useContext(AppContext);
    const vreli_domain   = process.env.REACT_APP_VRELI_DOMAIN; 
    const react_url      = process.env.REACT_APP_URL; 
    const navigate       = useNavigate();
    const location       = useLocation()
    const params         = new URLSearchParams(location.search)
    let url_email        = params.get("email")
    let url_otp          = params.get("otp")
    let create_workspace_url = `${react_url}/create-workspace?email=${url_email}&otp=${url_otp}`;

    return (
        <section className="block block--find-workspace">
            <div className="container">
                <div className="row block--flex">
                    <div className="col-md-6 col-lg-5" >
                        <div className="workspace-wrap">
                            <div className="workspace__title-wrap">
                                <h2 className="workspace__title">Your Workspaces</h2>
                            </div>
                            <div className="workspace__content-wrap">
                                <p className="workspace__content">You&#039;re already a member of these Workspaces</p>
                                <div className="workspace__available-wrap">
                                    {/* <!-- Repeatable block --> */}
                                          {workspaces ? 
                                          (
                                            <>
                                            {workspaces.map((val) => {
                                                return (
                                                    <Card
                                                        key={val.id}
                                                        domain={val.domain}
                                                        link={"http://" + val.domain + "." + vreli_domain}
                                                    />
                                                );
                                            })}
                                            </>
                                            
                                            ) :
                                             
                                            (
                                                <span class="workspace__name">No workspace found! </span>
                                            )}
                                    {/* <!-- Repeatable block --> */}
                                </div>
                                <p className="workspace__content">  Want to create new workspace? Please <a href={create_workspace_url} className="text-primary">click here</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FindWorkspace