import axios from 'axios'
import React,{useState} from 'react'
import SuccessAlert from './SuccessAlert'
import ErrorAlert from './ErrorAlert'
import { useLocation,useNavigate } from "react-router-dom"

const GetStarted = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let url_email = params.get("email")
    const [Flash,setFlash] = useState("");
    const [Message,setMessage] = useState("");

    const submitEmail = (event) => {
        event.preventDefault()
        const email = event.target.email.value;

        //Post api
         const data = {
            email : email,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL; 
        const url = `${env_url}/apis/generate-otp`;
        axios.post(url,data)
        .then(res => {
            if(res.data['error']==0 || res.data['error']==3)
            {
                //redirect
                navigate(`/create-confirm-otp?email=${email}`);
            }
            else
            {
                setMessage(res.data['error_message']);
                setFlash("error");
            }
        })
    }

    return (
        <section class="block block--split block--flex block--provide-email block--mobile-reverse block--overflow-hidden">
        <div class="col-md-4">
            <div class="workspace-wrap workspace-wrap--input">
                <div class="workspace__title-wrap">
                    <h2 class="workspace__title">Enter your email</h2>
                </div>
                <div class="workspace__content-wrap">
                    <form method="POST" class="form form--workspace form--email block__content--center" onSubmit={submitEmail}>
                        <input type="hidden" name="_token" value="vdvvtlIge3P8IVA0ZPQ1qrnTSqFSto8qX2hB1TPf" />
                        <div class="form__input-group">
                            <label class="form__control-label"></label>
                                <input type="email" class="form__control form__control--whitebg js-input-email" name="email" placeholder="" autocomplete="off" />
                        </div>
                        <div className="flash-message">
                            {/* Flash Message */}
                                {Flash=="success"?<SuccessAlert data={Message} /> : null}
                                {Flash=="error"?<ErrorAlert data={Message} /> : null}
                            {/* Flash Message */}
                        </div>
                        <button class="btn form__input-submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </section>
    )
}

export default GetStarted