import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './common/Header'
import FindConfirmOTP from './FindConfirmOTP'
import CreateConfirmOTP from './CreateConfirmOTP'
import CreateWorkspace from './CreateWorkspace'
import FindWorkspace from './FindWorkspace'
import GetStarted from './GetStarted'
import FindProvideEmail from './FindProvideEmail'
import CreateProvideEmail from './CreateProvideEmail'
import Home from './Home'

const index = () => {
    return (
        <div>
            <Header />
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route exact path="/get-started" element={<GetStarted />} />
                    <Route exact path="/find-confirm-otp" element={<FindConfirmOTP />} />
                    <Route exact path="/create-confirm-otp" element={<CreateConfirmOTP />} />
                    <Route exact path="/find-workspace" element={<FindWorkspace />} />
                    <Route exact path="/create-workspace" element={<CreateWorkspace />} />
                    <Route exact path="/find-provide-email" element={<FindProvideEmail />} />
                    <Route exact path="/create-provide-email" element={<CreateProvideEmail />} />
                </Routes>
            </main>
        </div>

    )
}

export default index