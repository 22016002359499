import React from 'react'

const SuccessAlert = (props) => {
    return (
        <div class="alert alert-info">
            {props.data}
        </div>
    )
}

export default SuccessAlert