import React,{ useContext, useEffect,useState } from 'react'
import { useLocation,useNavigate } from "react-router-dom"
import axios from 'axios'
import { AppContext } from '../contexts/AppProvider'
import SuccessAlert from './SuccessAlert'
import ErrorAlert from './ErrorAlert'

const ConfirmOTP = () => {

    const {setWorkspaces} = useContext(AppContext)

    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const url_email = params.get("email")
    const react_url = process.env.REACT_APP_URL; 
    const find_provide_email_url = `${react_url}/find-provide-email`;

    const [input1,setInput1] = useState("");
    const [input2,setInput2] = useState("");
    const [input3,setInput3] = useState("");
    const [input4,setInput4] = useState("");
    const [input5,setInput5] = useState("");
    const [input6,setInput6] = useState("");
    const [showMessage,setShowMessage] = useState("");
    const [Sdata,setSdata]   = useState("");
    const [Flash,setFlash] = useState("");
    const [Message,setMessage] = useState("");

    const inputEvent1 = (event) => {
        setInput1(event.target.value);
        if(event.target.value)
        {
            //focus on next input
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    const inputEvent2 = (event) => {
        setInput2(event.target.value);
        if(event.target.value)
        {
            //focus on next input
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    const inputEvent3 = (event) => {
        setInput3(event.target.value);
        if(event.target.value)
        {
            //focus on next input
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    const inputEvent4 = (event) => {
        setInput4(event.target.value);
        if(event.target.value)
        {
            //focus on next input
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    const inputEvent5 = (event) => {
        setInput5(event.target.value);
        if(event.target.value)
        {
            //focus on next input
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    const inputEvent6 = (event) => {
        setInput6(event.target.value);
    };

    if(input1!='' && input2!='' && input3!='' && input4!='' && input5!='' && input6!='')
    {
        let otp = `${input1}${input2}${input3}${input4}${input5}${input6}`;
        //Post api
        const data = {
            email : url_email,
            otp : otp,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL; 
        const url = `${env_url}/apis/find-workspace`;
        axios.post(url,data)
        .then(res => {
            if(res.data['error']==0)
            {
                setFlash("success");
                setWorkspaces(res.data.workspaces_data)
                //redirect
                navigate(`/find-workspace?email=${url_email}&otp=${otp}`);
            }
            else
            {
                setMessage(res.data['error_message']);
                setFlash("error");
            }

        })
    }
        
    return (
        <section className="block block--confirmotp">
            <div className="container">
                <div className="row block--flex block--mobile-reverse">
                    <div className="block__content-left col-md-6 col-xl-5">
                        <div className="workspace-wrap">
                            <div className="workspace__title-wrap">
                                <h2 className="workspace__title">Check your email!</h2>
                            </div>
                            <div className="workspace__content-wrap">
                                <p className="workspace__content">We’ve sent a 6-digit confirmation code to <b> {url_email} </b>. It will expire shortly, so enter it soon. <br /> Wrong email address? Please <a href={find_provide_email_url} className="text-primary">re-enter email address</a>
                                </p>
                                <p className="workspace__content"><b>Please enter OTP</b></p>
                                <form method="POST" className="form--otp">
                                    <input type="hidden" name="otp" value="" />
                                    <div className="workspace-otp__wrap input-otp-main-wrapper">
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="1" onChange={inputEvent1} value={input1} autoFocus />
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="2" onChange={inputEvent2} value={input2} />
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="3" onChange={inputEvent3} value={input3} />
                                        {/* <span className="workspace__inline-input__separator">-</span> */}
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="4" onChange={inputEvent4} value={input4} />
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="5" onChange={inputEvent5} value={input5} />
                                        <input type="text" maxlength="1" className="workspace__inline-input inline_input" data-order="6" onChange={inputEvent6} value={input6} />
                                    </div>

                                    {Flash=="success"
                                    ?
                                    <div className="workspace-otp__verify-wrap verify_message" style={{display: 'bloxk'}}>
                                        <span className="workspace-otp__verify">Please wait, while we are verifying & redirecting your
                                            request</span>
                                        <div className="newtons-cradle">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                    }
                                    
                                    {/* <!-- Alert Flash Message --> */}
                                    <div className="flash-message">
                                        {Flash=="error"?<ErrorAlert data={Message} /> : null}
                                    </div>
                                    {/* <!-- End Alert Flash Message --> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConfirmOTP;
// export {Sdata};