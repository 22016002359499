import axios from 'axios'
import React from 'react'
import { useLocation, useNavigate } from "react-router-dom"

const GetStarted = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let url_email = params.get("email")

    const FindOtp = (event) => {
        //Post api
        event.preventDefault()
        const data = {
            email: url_email,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL;
        const url = `${env_url}/apis/generate-otp`;
        axios.post(url, data)
            .then(res => {
                // console.log('getresponse',res)
                if(res.data['error']==0 || res.data['error']==3)
                {
                    //redirect
                    navigate(`/find-confirm-otp?email=${url_email}`);
                }

            }).catch(e => {
                console.log(e);
            });

        //redirect
        // navigate(`/find-confirm-otp?email=${url_email}`);
    }

    const CreateOtp = (event) => {
        //Post api
        event.preventDefault()
        const data = {
            email: url_email,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL;
        const url = `${env_url}/apis/generate-otp`;
        axios.post(url, data)
            .then(res => {
                // console.log('getresponse',res)
                if(res.data['error']==0 || res.data['error']==3)
                {
                    //redirect
                    navigate(`/create-confirm-otp?email=${url_email}`);
                }
            }).catch(e => {
                console.log(e);
            });

        //redirect
        // navigate(`/create-confirm-otp?email=${url_email}`);
    }

    return (
        <section className="block block--split block--flex block--getstarted block--mobile-reverse block--overflow-hidden row">
            <div className="col-md-4">
                <div className="workspace-wrap">
                    <div className="workspace__title-wrap">
                        <h2 className="workspace__title">Lets Get Started</h2>
                    </div>
                    <div className="workspace__content-wrap">
                        <p className="workspace__content">In Vreli<sup>&reg;</sup>, team will collaborate in company workspace. Here we manage projects, bucket and get our work done efficiently & effectively.</p>
                        <a className="workspace__link" href="" onClick={FindOtp}>
                            Find Your Workspaces
                            {/* <span className="workspace__link-append"><i className="icon icon-chevron-right"></i></span> */}
                        </a>
                        <a className="workspace__link" href="" onClick={CreateOtp}>
                            Create a New Workspace
                            {/* <span className="workspace__link-append"><i className="icon icon-chevron-right"></i></span> */}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetStarted