import React, { useState } from 'react'

const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
    const [workspaces, setWorkspaces] = useState([]);


    return (
        <AppContext.Provider value={{workspaces,setWorkspaces}}>
            {children}
        </AppContext.Provider>
    )
}

export {AppContext}