// import axios from 'axios'
import React,{ useEffect,useState } from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import Header from './common/Header'
import SuccessAlert from './SuccessAlert'
import ErrorAlert from './ErrorAlert'

const Home = () => {
    const navigate = useNavigate();
    const [Flash,setFlash] = useState("");
    const [Message,setMessage] = useState("");

    const getStarted = (event) => {
        event.preventDefault()
        const email = event.target.email.value;

        //Post api
        const data = {
            email : email,
        }

        let env_url = process.env.REACT_APP_LUMEN_URL; 
        const url = `${env_url}/apis/get-started`;
        axios.post(url,data)
        .then(res => {
            if(res.data['error']==0)
            {
                //redirect
                navigate(`get-started?email=${email}`);
            }
            else
            {
                setMessage(res.data['error_message']);
                setFlash("error");
            }
        }).catch(e => {
            console.log(e);
        });
    }

    return (
        <section className="block block--split block--find-workspace block--banner"> 
            <div className="container-fluid">
                <div className="block__title-wrap block__title-wrap--sm-block">
                    <h1 className="banner__title block__title">
                        An Integrated Platform for Product Development
                    </h1>
                    
                    <p className="banner__subtext">Let’s co-create a better tomorrow. Sign up for free*.</p>
                </div>
                <div className="block--flex flex-sm-reverse">
                    <div className="col-sm-12 col-md-offset-4 col-md-4">
                        <div className="block__title-wrap block__title-wrap--sm-hidden">
                            <h1 className="block__title">
                                An Integrated Platform for Product Development 
                            </h1>
                            <p className="banner__subtext">Let’s co-create a better tomorrow. Sign up for free*.</p>
                        </div>
                        <div className="banner__content-wrap">
                            <form className="form" onSubmit={getStarted}>
                                <div className="form__input-group form__input-group--banner">
                                    <input className="form__input-email" type="email" name="email" placeholder="Enter Email Address"
                                        autoComplete="off" required />
                                    <button className="form__button get-started-button" type="submit" name="submit">GET STARTED</button>
                                </div>
                            </form>
                            <div className="flash-message">
                            {/* Flash Message */}
                                {Flash=="success"?<SuccessAlert data={Message} /> : null}
                                {Flash=="error"?<ErrorAlert data={Message} /> : null}
                            {/* Flash Message */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home