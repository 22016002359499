import Main from './components/';
import React from 'react';
import './index.css';

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
