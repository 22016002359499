import React from 'react';

function Card(props){
    return (
    <>
    <a className="workspace__available-link" href={props.link}>
        <div className="workspace__available">
            <span className="workspace__name-wrap">
                <span className="workspace__name-icon">P</span>
                <span className="workspace__name">{props.domain}</span>
            </span>
            <span className="workspace__begin">
                <span className="workspace__begin-text">Begin</span>
                <span className="workspace__begin-arrow"></span>
            </span>
        </div>
    </a> 
    </>
    )
}

export default Card;
 